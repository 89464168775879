body {
  margin: 0
}

hr {
  background-color: dimgray;
  height: 1px;
  border: none;
}

.MuiDrawer-root a[href^='/gdprCases'] {
  display: none;
}
